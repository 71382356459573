import React from "react"
import Layout from "../../components/layout/Layout"
import Content from "../../components/content/Content"

export default function HomePage() {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
