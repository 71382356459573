const theme = {
  color: {
    primary: process.env.REACT_APP_THEME_COLOR_PRIMARY || "#F26522",
    secondary: process.env.REACT_APP_THEME_COLOR_SECONDARY || "#00335B",
    tertiary: process.env.REACT_APP_THEME_COLOR_TERTIARY || "#2D3748",
    quaternary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#ffffff",
    quinary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#4A5568",
    senary: process.env.REACT_APP_THEME_COLOR_SENARY || "#CFD9EA",
    septenary: process.env.REACT_APP_THEME_COLOR_SEPTENARY || "#A5B9FF",
    octonary: process.env.REACT_APP_THEME_COLOR_OCTONARY || "#F7FAFC",
    nonary: process.env.REACT_APP_THEME_COLOR_NONARY || "#A2ACBD",
    denary: process.env.REACT_APP_THEME_COLOR_DENARY || "#F2F5FF",
    undenary: process.env.REACT_APP_THEME_COLOR_UNDENARY || "#CCF6EC",
    duodenary: process.env.REACT_APP_THEME_COLOR_DUODENARY || "#E9D8FD",
    tredenary: process.env.REACT_APP_THEME_COLOR_TREDENARY || "#FEEBCB",
    quattuordenary: process.env.REACT_APP_THEME_COLOR_QUATTUORDENARY || "#8E99AB",
    quinquadenary: process.env.REACT_APP_THEME_COLOR_QUINQUADENARY || "#E4EBF9",
  },
  logo: {
    type1: {
      url: process.env.REACT_APP_THEME_LOGO_URL || "/images/csg-logo-1.png",
    },
    type2: {
      url: process.env.REACT_APP_THEME_LOGO_URL || "/images/csg-logo-2.png",
    }
  },
  banner: {
    url: process.env.REACT_APP_THEME_LOGO_URL || "/images/csg-banner-2.png",
  },
  company: {
    name: process.env.REACT_APP_THEME_COMPANY_NAME || "CSG",
  },
}

export default theme