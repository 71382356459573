import React, { useState } from "react"
import InputMask from "react-input-mask"
import {
  Button,
  Checkbox,
  Flex,
  Text,
  VStack,
  Link as ChakraLink,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Input,
  Image,
  Hide,
} from "@chakra-ui/react"
import { HiMiniArrowRight } from "react-icons/hi2"
import theme from "../../Themes"

export default function Banner() {
  const [licensePlate, setLicensePlate] = useState('')
  const [checked, setChecked] = useState(true)

  const save = () => {
    if (!checked) {
      alert("Você deve aceitar as políticas de privacidade e os termos de uso.")
      return
    }

    if (licensePlate.length < 8) {
      alert("Digite uma placa");
      return
    }

    const baseUrl = process.env.REACT_APP_WEBAPP_BASE_URL
    window.location.href = `${baseUrl}/busca/${licensePlate}`
  }

  return (
    <Flex
      w="100%"
      minH="900px"
      backgroundImage={theme.banner.url}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent="center"
      alignItems="center"
      direction="column"
      scale="0.1"
      px="1rem"
    >
      <Flex
        w="100%"
        maxW="1128px"
        mt="22rem"
        mb="2rem"
        justifyContent="space-between"
        direction={{ base: "column", sm: "column", md: "column", lg: "row" }}
        alignItems="center"
        gap="2rem"
      >
        <VStack
          w="100%"
          maxW={{ base: "100%", sm: "100%", md: "90%", lg: "100%" }}
          spacing={0}
          alignItems="flex-start"
        >
          <Text
            maxW={{ base: "300px", sm: "332px", md: "542px" }}
            as="span"
            fontSize={{
              base: "2.75rem",
              sm: "2.75rem",
              md: "3rem",
              lg: "3.75rem",
            }}
            fontWeight="600"
            color={theme.color.quaternary}
            lineHeight={{ base: "43.2px", sm: "60px" }}
            letterSpacing="-1.8px"
          >
            Caminhos que cuidam de
            <Text
              ml="0.75rem"
              as="span"
              fontSize={{
                base: "2.75rem",
                sm: "2.75rem",
                md: "3rem",
                lg: "3.75rem",
              }}
              fontWeight="600"
              color={theme.color.primary}
              lineHeight={{ base: "43.2px", sm: "60px" }}
              letterSpacing="-1.8px"
            >
              você
            </Text>
            .
          </Text>
          <Text
            maxW={{ base: "", sm: "500px", md: "631px" }}
            mt="0.5rem"
            color={theme.color.quaternary}
            fontSize={{
              base: "1.5rem",
              sm: "1.5rem",
              md: "1.75rem",
              lg: "1.88rem",
            }}
            fontWeight={{ base: "300", sm: "300", md: "400" }}
            lineHeight="36px"
          >
            Facilitamos os pagamentos para que você tenha tempo para quem mais
            importa.
          </Text>
          <Hide below="lg">
            <Text
              maxW="625px"
              fontSize="1.25rem"
              fontWeight="400"
              color={theme.color.octonary}
              mt="1rem"
              mb="1.5rem"
              lineHeight="35px"
            >
              Com o novo sistema de pagamento eletrônico de pedágio, não há mais
              necessidade de cabines nem filas, e ainda é possível obter
              descontos ao efetuar o pagamento antecipado.
            </Text>
          </Hide>
        </VStack>
        <Flex
          p="2.25rem"
          w="100%"
          maxW={{ base: "100%", sm: "100%", md: "90%", lg: "424px" }}
          borderRadius="0.750rem"
          bg="linear-gradient(128deg, rgba(255, 255, 255, 0.13) 5.72%, rgba(255, 255, 255, 0.00) 52.13%), rgba(255, 255, 255, 0.09)"
          backdropFilter="blur(6px)"
          backdropBlur="6px"
        >
          <Flex w="100%" direction="column" justifyContent="center">
            <Text
              mb="0.25rem"
              fontSize="1.25rem"
              fontWeight="600"
              color={theme.color.quaternary}
            >
              Consulta avulsa
            </Text>
            <FormControl bg={theme.color.quaternary} borderRadius="0.5rem">
              <InputGroup h="100%">
                <InputLeftAddon h="100%" bg="none">
                  <Image src="/images/CarIcon.png" alt="Ícone de um carro" />
                </InputLeftAddon>
                <Input
                  as={InputMask}
                  mask="aaa-9*99"
                  maskChar={null}
                  variant="outline"
                  size="lg"
                  type="text"
                  color={theme.color.tertiary}
                  value={licensePlate}
                  onChange={event => setLicensePlate(event.target.value.toUpperCase())}
                  placeholder="Placa do veículo"
                  _placeholder={{ color: `${theme.color.nonary}` }}
                />
              </InputGroup>
            </FormControl>
            <Button
              mt="1.5rem"
              mb="2.5rem"
              w="100%"
              size="lg"
              variant="solid"
              bg={theme.color.primary}
              color={theme.color.quaternary}
              borderRadius="0.375rem"
              border="none"
              fontSize="1rem"
              fontWeight="600"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
              _hover={{
                bg: `${theme.color.primary}`,
                color: `${theme.color.quaternary}`,
              }}
              onClick={save}
            >
              Buscar débitos
              <HiMiniArrowRight />
            </Button>
            <Checkbox
              as="span"
              defaultChecked
              size="lg"
              colorScheme="orange"
              color={theme.color.senary}
              fontSize="0.875rem"
              fontWeight="400"
              alignItems="baseline"
              checked={checked}
              onChange={event => setChecked(!checked)}
            >
              Estou de acordo com a{" "}
              <ChakraLink
                color={theme.color.primary}
                fontWeight="700"
                target="_blank"
                href="https://www.csg.com.br/pagina/politicas-de-privacidade"
              >
                Política de Privacidade
              </ChakraLink>{" "}
              e{" "}
              <ChakraLink color={theme.color.primary} fontWeight="700">
                Termos e Condições de Uso
              </ChakraLink>
            </Checkbox>
          </Flex>
        </Flex>
      </Flex>
      <Hide below="lg">
        <Flex
          mt="auto"
          py="1.5rem"
          w="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="1.5rem"
        >
          <Image src="/images/MouseIcon.png" alt="Ícone de um mouse" />
          <Text
            fontSize="0.875rem"
            fontWeight="400"
            color={theme.color.quaternary}
          >
            Role para ver mais
          </Text>
        </Flex>
      </Hide>
    </Flex>
  )
}
