import React from "react"
import { Button, Flex, HStack, Image, Link, Show, Text } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Autoplay, Pagination, Navigation } from "swiper/modules"
import Banner from "../banner/Banner"
import theme from "../../Themes"
import "./Content.scss"

export default function Content() {
  return (
    <Flex w="100%" bg={theme.color.quaternary} direction="column">
      <Banner />
      <Flex w="100%" p="2rem" bg={theme.color.quaternary}>
        <Flex
          m="1rem auto"
          w="100%"
          maxW="1106px"
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
          alignItems="center"
        >
          <Show below="md">
            <Text
              fontSize="1rem"
              fontWeight="400"
              color={theme.color.secondary}
            >
              Free Flow
            </Text>
          </Show>
          <Flex
            m={{ base: "1.25rem 0 1.25rem 0", md: "0" }}
            w="100%"
            maxW="628px"
            maxH="444px"
            alignItems="center"
          >
            <Image
              src="/images/electronic-toll.png"
              alt="Imagem de um pedágio eletrônico"
            />
          </Flex>
          <Flex w="100%" maxW="418px" flexDirection="column">
            <Flex
              direction="column"
              w="100%"
              alignItems={{ base: "center", sm: "flex-start" }}
            >
              <Show above="md">
                <Text
                  fontSize="1rem"
                  fontWeight="400"
                  color={theme.color.secondary}
                >
                  Free Flow
                </Text>
              </Show>
              <Text
                textAlign={{ base: "center", sm: "center", md: "left" }}
                fontSize={{ base: "1.75rem", sm: "1.88rem" }}
                fontWeight="700"
                color={theme.color.primary}
                lineHeight="120%"
              >
                Pedágio eletrônico
              </Text>
              <Text
                textAlign={{ base: "center", sm: "center", md: "left" }}
                fontSize={{ base: "1.75rem", sm: "1.88rem" }}
                fontWeight="700"
                color={theme.color.secondary}
                lineHeight="120%"
              >
                sem paradas transformando a mobilidade nas estradas
              </Text>
            </Flex>
            <Text
              textAlign={{ base: "center", sm: "center", md: "left" }}
              fontSize="1rem"
              fontWeight="500"
              color={theme.color.quinary}
              mt="1rem"
              mb="1.5rem"
            >
              O sistema de cobrança Free Flow é simples e eficiente: quando seu
              veículo passa pelo pórtico, sensores de alta resolução capturam as
              informações do tag e da placa. Em seguida, utilizando tecnologia
              de reconhecimento de caracteres e sensores de alta definição, o
              sistema identifica a placa e emite automaticamente a cobrança para
              o veículo.
            </Text>
            <HStack
              gap="1.25rem"
              alignItems="flex-start"
              justifyContent={{ base: "center", sm: "flex-start" }}
            >
              <Link 
                href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/cadastrar`}
                target="_blank"
              >
                <Button
                  size="lg"
                  variant="solid"
                  bg={theme.color.primary}
                  color={theme.color.quaternary}
                  borderRadius="0.375rem"
                  border="none"
                  fontSize="1rem"
                  fontWeight="700"
                  _hover={{
                    bg: `${theme.color.primary}`,
                    color: `${theme.color.quaternary}`,
                  }}
                >
                  Criar conta
                </Button>
              </Link>
              <Link 
                href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/login`}
                target="_blank"
              >
                <Button
                  size="lg"
                  variant="outline"
                  bg={theme.color.quaternary}
                  color={theme.color.primary}
                  borderRadius="0.375rem"
                  border="none"
                  fontSize="1rem"
                  fontWeight="700"
                  _hover={{
                    bg: `${theme.color.quaternary}`,
                    color: `${theme.color.primary}`,
                  }}
                >
                  Login
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" p="2rem" bg={theme.color.denary}>
        <Flex m="1rem auto" w="100%" maxW="941px" flexDirection="column">
          <Flex
            w="100%"
            justifyContent="center"
            mb={{ base: "0rem", sm: "0rem", md: "0rem", lg: "3.13rem" }}
          >
            <Text
              color={theme.color.secondary}
              fontSize={{ base: "1.75rem", sm: "1.88rem" }}
              fontWeight="700"
            >
              Benefícios
            </Text>
          </Flex>
          <Show above="1023px">
            <Flex w="100%" justifyContent="center" gap="6.25rem">
              <Flex
                direction="column"
                w="100%"
                maxWidth="247px"
                alignItems="center"
              >
                <Flex
                  w="92px"
                  h="92px"
                  borderRadius="0.750rem"
                  bg={theme.color.quinquadenary}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src="/images/BlueCarIcon.png"
                    alt="Ícone de um carro"
                  />
                </Flex>
                <Text
                  mt="1.75rem"
                  mb="0.5rem"
                  textAlign="center"
                  fontSize="1rem"
                  fontWeight="700"
                  color={theme.color.secondary}
                >
                  Sem filas
                </Text>
                <Text
                  textAlign="center"
                  w="100%"
                  maxWidth="247px"
                  fontSize="0.875rem"
                  fontWeight="400"
                  color={theme.color.tertiary}
                >
                  Adeus às longas esperas nas praças de pedágio. Com o pedágio
                  eletrônico, você passa sem parar, garantindo viagens mais
                  rápidas e fluidas.
                </Text>
              </Flex>
              <Flex
                direction="column"
                w="100%"
                maxWidth="247px"
                alignItems="center"
              >
                <Flex
                  w="92px"
                  h="92px"
                  borderRadius="0.750rem"
                  bg={theme.color.quinquadenary}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src="/images/BlueSofaIcon.png"
                    alt="Ícone de um carro"
                  />
                </Flex>
                <Text
                  mt="1.75rem"
                  mb="0.5rem"
                  textAlign="center"
                  fontSize="1rem"
                  fontWeight="700"
                  color={theme.color.secondary}
                >
                  Comodidade
                </Text>
                <Text
                  textAlign="center"
                  w="100%"
                  maxWidth="247px"
                  fontSize="0.875rem"
                  fontWeight="400"
                  color={theme.color.tertiary}
                >
                  Ao criar uma conta, você tem a opção de adicionar créditos,
                  garantindo que o pagamento seja feito automaticamente.
                </Text>
              </Flex>
              <Flex
                direction="column"
                w="100%"
                maxWidth="247px"
                alignItems="center"
              >
                <Flex
                  w="92px"
                  h="92px"
                  borderRadius="0.750rem"
                  bg={theme.color.quinquadenary}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src="/images/BlueBellIcon.png"
                    alt="Ícone de um carro"
                  />
                </Flex>
                <Text
                  mt="1.75rem"
                  mb="0.5rem"
                  textAlign="center"
                  fontSize="1rem"
                  fontWeight="700"
                  color={theme.color.secondary}
                >
                  Alertas
                </Text>
                <Text
                  textAlign="center"
                  w="100%"
                  maxWidth="247px"
                  fontSize="0.875rem"
                  fontWeight="400"
                  color={theme.color.tertiary}
                >
                  No aplicativo, você recebe notificações sempre que uma
                  cobrança é gerada.
                </Text>
              </Flex>
            </Flex>
          </Show>
          <Show below="1023px">
            <Flex w="100%" className="swiper-container">
              <Swiper
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                  dynamicBullets: true,
                }}
              >
                <SwiperSlide>
                  {" "}
                  <Flex
                    direction="column"
                    w="100%"
                    maxWidth="247px"
                    minHeight="247px"
                    alignItems="center"
                  >
                    <Flex
                      w="92px"
                      h="92px"
                      borderRadius="0.750rem"
                      bg={theme.color.quinquadenary}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src="/images/BlueCarIcon.png"
                        alt="Ícone de um carro"
                      />
                    </Flex>
                    <Text
                      mt="1.75rem"
                      mb="0.5rem"
                      textAlign="center"
                      fontSize="1rem"
                      fontWeight="700"
                      color={theme.color.secondary}
                    >
                      Sem filas
                    </Text>
                    <Text
                      textAlign="center"
                      w="100%"
                      maxWidth="247px"
                      fontSize="0.875rem"
                      fontWeight="400"
                      color={theme.color.tertiary}
                    >
                      Adeus às longas esperas nas praças de pedágio. Com o
                      pedágio eletrônico, você passa sem parar, garantindo
                      viagens mais rápidas e fluidas.
                    </Text>
                  </Flex>
                </SwiperSlide>
                <SwiperSlide>
                  <Flex
                    direction="column"
                    w="100%"
                    maxWidth="247px"
                    minHeight="247px"
                    alignItems="center"
                  >
                    <Flex
                      w="92px"
                      h="92px"
                      borderRadius="0.750rem"
                      bg={theme.color.quinquadenary}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src="/images/BlueSofaIcon.png"
                        alt="Ícone de um carro"
                      />
                    </Flex>
                    <Text
                      mt="1.75rem"
                      mb="0.5rem"
                      textAlign="center"
                      fontSize="1rem"
                      fontWeight="700"
                      color={theme.color.secondary}
                    >
                      Comodidade
                    </Text>
                    <Text
                      textAlign="center"
                      w="100%"
                      maxWidth="247px"
                      fontSize="0.875rem"
                      fontWeight="400"
                      color={theme.color.tertiary}
                    >
                      Ao criar uma conta, você tem a opção de adicionar
                      créditos, garantindo que o pagamento seja feito
                      automaticamente.
                    </Text>
                  </Flex>
                </SwiperSlide>
                <SwiperSlide>
                  <Flex
                    direction="column"
                    w="100%"
                    maxWidth="247px"
                    minHeight="247px"
                    alignItems="center"
                  >
                    <Flex
                      w="92px"
                      h="92px"
                      borderRadius="0.750rem"
                      bg={theme.color.quinquadenary}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src="/images/BlueBellIcon.png"
                        alt="Ícone de um carro"
                      />
                    </Flex>
                    <Text
                      mt="1.75rem"
                      mb="0.5rem"
                      textAlign="center"
                      fontSize="1rem"
                      fontWeight="700"
                      color={theme.color.secondary}
                    >
                      Alertas
                    </Text>
                    <Text
                      textAlign="center"
                      w="100%"
                      maxWidth="247px"
                      fontSize="0.875rem"
                      fontWeight="400"
                      color={theme.color.tertiary}
                    >
                      No aplicativo, você recebe notificações sempre que uma
                      cobrança é gerada.
                    </Text>
                  </Flex>
                </SwiperSlide>
              </Swiper>
            </Flex>
          </Show>
          <HStack w="100%" gap="1rem" justifyContent="center" mt="4.25rem">
            <Link
              href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/cadastrar`}
              target="_blank"
            >
              <Button
                size="lg"
                variant="solid"
                bg={theme.color.secondary}
                color={theme.color.quaternary}
                borderRadius="0.375rem"
                border="none"
                fontSize="1rem"
                fontWeight="700"
                _hover={{
                  bg: `${theme.color.secondary}`,
                  color: `${theme.color.quaternary}`,
                }}
              >
                Criar conta agora
              </Button>
            </Link>
            <Link
              href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/login`}
              target="_blank"
            >
              <Button
                size="lg"
                variant="outline"
                bg="none"
                color={theme.color.secondary}
                borderRadius="0.375rem"
                border="none"
                fontSize="1rem"
                fontWeight="700"
                _hover={{
                  bg: `none`,
                  color: `${theme.color.secondary}`,
                }}
              >
                Login
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Flex>
      <Flex w="100%" p="2rem" bg={theme.color.quaternary}>
        <Flex
          m="1rem auto"
          w="100%"
          maxW="1000px"
          gap={{ base: "2.5rem", sm: "3.75rem" }}
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
          alignItems="center"
        >
          <Flex w="100%" maxW="402px" flexDirection="column">
            <Flex
              direction="column"
              w="100%"
              alignItems={{ base: "center", sm: "flex-start" }}
            >
              <Text
                textAlign={{ base: "center", sm: "center", md: "left" }}
                fontSize={{ base: "1.75rem", sm: "1.88rem" }}
                fontWeight="700"
                color={theme.color.primary}
                lineHeight="120%"
              >
                Economize 5% com Tags
              </Text>
              <Text
                textAlign={{ base: "center", sm: "center", md: "left" }}
                fontSize={{ base: "1.75rem", sm: "1.88rem" }}
                fontWeight="700"
                color={theme.color.secondary}
                lineHeight="120%"
              >
                de pagamento automático de pedágios
              </Text>
            </Flex>
            <Text
              textAlign={{ base: "center", sm: "center", md: "left" }}
              fontSize="1rem"
              fontWeight="500"
              color={theme.color.quinary}
              mt="0.750rem"
            >
              Nós valorizamos a sua conveniência nas estradas e é por isso que
              aceitamos Tags, adesivos de pagamento automático de pedágios. Ao
              fixar a sua Tag no para-brisa do veículo, você desfruta de
              inúmeras vantagens, incluindo um desconto exclusivo
            </Text>
          </Flex>
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Image
              src="/images/companies.png"
              alt="Imagem contendo o logo das empresas"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" p="2rem" bg={theme.color.denary}>
        <Flex
          m="1rem auto"
          w="100%"
          maxW="936px"
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
          alignItems="center"
        >
          <Flex
            w="100%"
            maxW="600px"
            alignItems="center"
            p="0px 15.122px 30.184px 15.259px"
          >
            <Image
              w="100%"
              maxWidth="436px"
              src="/images/conect.png"
              alt="Imagem de um rapaz do suporte ao cliente"
            />
          </Flex>
          <Flex w="100%" maxW="418px" flexDirection="column">
            <Flex
              w="100%"
              justifyContent={{ base: "center", sm: "flex-start" }}
            >
              <Text
                alignSelf={{ base: "center", sm: "flex-start" }}
                textAlign={{ base: "center", sm: "left" }}
                fontSize={{ base: "1.75rem", sm: "1.88rem" }}
                fontWeight="700"
                color={theme.color.primary}
                lineHeight="120%"
              >
                Conecte-se conosco
              </Text>
            </Flex>
            <Text
              alignSelf={{ base: "center", sm: "flex-start" }}
              textAlign={{ base: "center", sm: "left" }}
              maxW="306px"
              fontSize="1.13rem"
              fontWeight="400"
              color={theme.color.quattuordenary}
              mt="0.125rem"
            >
              Estamos à disposição para atendê-lo e responder às suas dúvidas.
            </Text>
            <Flex
              direction="column"
              mb="0.750rem"
              mt="1.25rem"
              alignItems={{ base: "center", sm: "flex-start" }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color={theme.color.quinary}
              >
                E-mail
              </Text>
              <Text
                fontSize="0.875rem"
                fontWeight="400"
                color={theme.color.quattuordenary}
              >
                atendimento@csg.com.br
              </Text>
            </Flex>
            <Flex
              direction="column"
              mb="1rem"
              alignItems={{ base: "center", sm: "flex-start" }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color={theme.color.quinary}
              >
                Emergências
              </Text>
              <Text
                fontSize="0.875rem"
                fontWeight="400"
                color={theme.color.quattuordenary}
              >
                0800 122 0240
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              justifyContent={{ base: "center", sm: "flex-start" }}
            >
              <Link
                href="https://www.csg.com.br/fale-conosco"
                target="_blank"
              >
                <Button
                  size="md"
                  variant="outline"
                  bg={theme.color.denary}
                  color={theme.color.tertiary}
                  borderRadius="0.375rem"
                  border={`1px solid ${theme.color.quattuordenary}`}
                  fontSize="0.875rem"
                  fontWeight="600"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="0.5rem"
                >
                  Formulário de contato
                  <Image
                    src="/images/OrangeExtrnalLinkIcon.png"
                    alt="Botão de redirecionamento à um link externo"
                  />
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
