import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Link as ChakraLink,
  HStack,
  Image,
  Box,
} from "@chakra-ui/react"
import theme from "../../../Themes"

export default function Footer() {
  return (
    <Flex w="100%" bg={theme.color.secondary} direction="column">
      <Box h="6px" w="100%" bg={theme.color.primary}></Box>
      <Flex w="100%" p="2rem">
        <Flex
          m="0 auto"
          w="100%"
          maxW="1126px"
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
          }}
          alignItems="center"
        >
          <Flex
            mt="0.750rem"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "1rem", sm: "0rem" }}
          >
            <Flex w="auto">
              <ChakraLink
                target="_blank"
                href="/"
              >
                <Image
                  maxWidth="80px"
                  src={theme.logo.type1.url}
                  alt="Logo da CSG"
                />
              </ChakraLink>
            </Flex>
            <HStack gap="1.32rem">
              <ChakraLink
                as={ReactRouterLink}
                to="https://www.facebook.com/csgcaminhos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/images/FacebookLogo.png" alt="Logo do Facebook" />
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="https://www.instagram.com/csgcaminhos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/InstagramLogo.png"
                  alt="Logo do Instagram"
                />
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="https://www.youtube.com/channel/UC7q1x7HqAm_fqvLIfmOyvMg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/images/YouTubeLogo.png" alt="Logo do YouTube" />
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="https://www.linkedin.com/company/csgcaminhos/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/images/LinkedInLogo.png" alt="Logo do LinkedIn" />
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="https://twitter.com/csgcaminhos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src="/images/TwitterLogo.png" alt="Logo do Twitter" />
              </ChakraLink>
            </HStack>
          </Flex>
          <Flex
            mt="2.5rem"
            mb="5rem"
            w="100%"
            justifyContent={{
              base: "center",
              sm: "center",
              md: "space-between",
            }}
            color={theme.color.quaternary}
            fontSize="0.875rem"
            fontWeight="500"
            gap={{ base: "1rem", sm: "1rem", md: "1rem" }}
            flexWrap={{ base: "wrap", sm: "wrap", md: "wrap" }}
          >
            <ChakraLink
              target="_blank"
              href="https://www.csg.com.br/pagina/a-csg"
            >
              Sobre a CSG
            </ChakraLink>
            <ChakraLink 
              target="_blank"
              href="https://www.csg.com.br/fale-conosco"
            >
              Emergências
            </ChakraLink>
            <ChakraLink
              target="_blank"
              href="https://www.csg.com.br/pagina/links-e-telefones-uteis"
            >
              Links e telefones úteis
            </ChakraLink>
            <ChakraLink
              target="_blank"
              href="https://www.csg.com.br/pagina/politicas-de-privacidade"
            >
              Política de Privacidade
            </ChakraLink>
            <ChakraLink as={ReactRouterLink} target="_blank" to="/">
              Termos e Condições de Uso
            </ChakraLink>
          </Flex>
          <Box h="1px" w="100%" bg="rgba(77, 112, 141, 0.60)" mb="1.5rem"></Box>
          <Flex w="100%" justifyContent="space-between">
            <Text
              fontSize="0.750rem"
              fontWeight="400"
              color={theme.color.senary}
            >
              © Caminhos da Serra Gaúcha
            </Text>
            <Text
              fontSize="0.875rem"
              fontWeight="500"
              color={theme.color.senary}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.25rem"
            >
              Made by
              <ChakraLink
                target="_blank"
                href="http://attri.com.br/"
              >
                <Image src="/images/attri-logo.png" alt="Logo da Attri" />
              </ChakraLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
