import React from "react"
import { Route, Routes } from "react-router-dom"
import HomePage from "./Home/HomePage"

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
    </Routes>
  )
}

export default Router
