import React from "react"
import ReactDOM from "react-dom/client"
//import "./index.scss";
import { ChakraProvider } from "@chakra-ui/react"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
)

reportWebVitals()
